import React from 'react'
import './Help.css'

const Help = () => {
  return (
    <div className="help" id='Contact'>
        <div className="help-content">
            <div className="help-text">
            <h2>How can <span>I help you?</span></h2>
            </div>
            <div className="h-divider"></div>
            <div className="help-btn">
                <button>Get in touch</button>
            </div>
        </div>
    </div>
  )
}

export default Help
