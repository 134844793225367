import timg2 from '../assets/timg2.png'

export const blog2Data = [
    {
        image: timg2,
        heading: 'Elsberg Syndrome',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'June 8, 2022'
    },
]

export default blog2Data