import image1 from '../assets/certificate1.png'
import image2 from '../assets/certificate2.png'
import image3 from '../assets/certificate3.png'

export const certificateData = [
    {
        image: image1,
        certificateName: 'Fakeeh',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'June 8, 2022'
    },
    {
        image: image2,
        certificateName: 'Physician & Surgeon',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'August 8, 2022'
    },
    {
        image: image3,
        certificateName: 'Canada',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'September 8, 2022'
    },
]