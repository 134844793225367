import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import {Link as LinkRoll} from 'react-scroll'
import {FaBars, FaTimes} from 'react-icons/fa'

const Navbar = () => {

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  return (
   
    <nav className="navbar">
      <div className="hamburger" onClick={handleClick}>
        {click? 
        <FaTimes size={20} style={{color: 'white'}}
        /> 
        : 
        <FaBars size={20} style={{color: 'white'}}
        />}
      </div>
        <ul className={click? "nav-links active" : "nav-links"}>
        <Link to='/'>
          <li className='nav-items'>Home</li>
          </Link>
        <LinkRoll activeClass='active'
                      to='About'
                      spy={true}
                      
                      smooth={true}>
                       <li className='nav-items'>  About </li>
                      </LinkRoll>
            <LinkRoll activeClass='active'
                      to='Education'
                      spy={true}
                      smooth={true}>
                        <li className='nav-items'>Education</li>
                        </LinkRoll>
            <LinkRoll activeClass='active'
                      to='Specialization'
                      spy={true}
                      smooth={true}>
                        <li className='nav-items'>Specialization</li>
                        </LinkRoll>
            <LinkRoll activeClass='active'
                      to='Career'
                      spy={true}
                      smooth={true}>
                        <li className='nav-items'>Career</li>
                        </LinkRoll>
            <Link to='/certificates'>
              <li className='nav-items'>Certificates</li>
              </Link>
            <Link to='/blogs'><li>Blogs</li></Link>
            <LinkRoll 
            activeClass='active'
            to='Contact'
            spy={true}
            smooth={true}>
              <li className='nav-items'>Contact</li>
              </LinkRoll>
        </ul>
    </nav>
  
    
  )
}

export default Navbar