import React from 'react'
import './Testimonial.css'
import {FaEye, FaHeart, FaInbox, FaShare} from 'react-icons/fa'
import timg1 from '../../assets/timg1.png'
import timg2 from '../../assets/timg2.png'
import timg3 from '../../assets/timg3.png'


const Testimonial = () => {

  return (
    <div className="testimonial">
        <div className="t-content">
            <div className="t-first">
                <div className="t-divider"></div>
                <h2>latest articles</h2>
            </div>
            {/* second */}

            <div className="t-second">
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg1} alt="" />
                    </div>
                   <div className="tt-content">
                   <div className="card-content">
                        <h4>Structural Barriers to<br/>
                        Healthy Lifestyles</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                        <a href="/blogs">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                   </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg2} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Elsberg<br/>
                        Syndrome</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg3} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Dementia vs.<br/>
                        Alzheimer's</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                                <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            {/* third */}

            <div className="t-third">
                <a href="/blogs">
                <button>View all</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Testimonial
