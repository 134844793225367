import React from 'react'
import './Certification.css'
import Certificate from '../../assets/certificate.png'

const Certification = () => {
  return (
    <div className="certification">
        <div className="certification-content">
            {/* left side */}
            <div className="c-left">
                <div className="c-left-content">
                    <div className="c-divider"></div>
                    <h2>Certifications</h2>
                </div>
                <div className="c-permanent">
                    <h2>permanent figure<br/>
                    in the <span>neurology<br/>community</span> in<br/>
                    Saudi Arabia</h2>
                </div>
            </div>
            {/* right side */}
            <div className="c-right">
                <div className="c-right-content">
                    <div></div>
                    <img src={Certificate} alt="" />
                </div>
                <div className="fakeeh">
                    <h5>Fakeeh medical certificate</h5>
                </div>
            </div>
           
        </div>
        <div className="c-btn">
                <a href="/certificates">
                <button>View all</button>
                </a>
            </div>
    </div>

  )
}

export default Certification
