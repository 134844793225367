import timg1 from '../assets/timg1.png'
import timg2 from '../assets/timg2.png'
import timg3 from '../assets/timg3.png'

export const testimonialData = [
    {
        image: timg1,
        heading: 'Structural Barriers to Healthy Lifestyles',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'June 8, 2022'
    },
    {
        image: timg2,
        heading: 'Elsberh Syndrome',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'August 8, 2022'
    },
    {
        image: timg3,
        heading: 'Dementia vs. Alzheimer\'s',
        review: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.',
        name: 'Dr. Ahmad Fallata',
        date: 'September 8, 2022'
    },
]
export default testimonialData