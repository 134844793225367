import React from 'react'
import './About.css'
import AboutAuthor from '../../assets/about-author.png'
import Dots from '../../assets/about-dots.png'

const About = () => {
  return (
    <div className="about" id='About'>
        <div className="about-left">
            <div className="about-about">
                <div className="about-divider"></div>
                <h2>About</h2>
                
            </div>
            <div className="about-img">
                <div></div>
                <img src={AboutAuthor} alt="" />
            </div>
        </div>
        <div className="about-right">
            <div className="a-img">
                <img src={Dots} alt="" />
            </div>
            <div className="a-right">
                <div className="a-right-divider"></div>
                <h2>Dream to become <br/>
                Saudi Arabia's best </h2>
                <h2>Neurologist</h2>
                <p>Dr. Ahmed Fallatah a 2005 graduate from Umm Al Qura university wanted to
                accomplish his Dream of becoming one of Saudi Arabia's best neurologist. In
                the following year he travelled to London Ontorio Canada and worked at the
                neurology department at western university</p>
                <p>Throughout the years from 2006 to 2009 dr Fallata worked in King Abdulaziz
                hospital in Makkah where he take his first role as a chief resident</p>
                <p>then in 2009 he joing University of Tabuk as a demonstrator</p>
            </div>
        </div>
    </div>
  )
}

export default About
