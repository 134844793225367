import React from 'react'

const Blog2Heading4 = () => {
  return (
    <div className="heading4">
        <div className="heading4-content">
            <div className="heading4-text">
                <div className="heading4-divider"></div>
                <h2>Heading 4</h2>
            </div>
            <div className="heading4-p">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                lacus vel facilisis.</p>
            </div>

            <div className="heading4-bullet">
                
                <div className="heading4-dot">
                    <div className="dotdot"></div>
                    <h4>Lorem ipsum dolor sit amet, consectetur adispiscing elit.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Praesent ornare arcu et malesuada suscipit.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Sed tincidunt elit a felis tristuque posuere.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Phasellus iaculis felis eu dolor ornare, non luctus ex consequat.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Curabitur et lectus ac velit molestie bibendum non luctus ex consequat.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Vivamus iaculis massa sit amet purus tempus, vitae viverra sem viverra.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Etiam in tellus luctus, convallis turpis in, pretium velit.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Suspendisse quis lorem vel ipsum ultrices mattis ut finibus turpis.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Suspendisse commodo sapien sit amet leo lacinia porttitor.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Vivamus tincidunt sapien et volutpat luctus.</h4>
                </div>
                <div className="heading4-dot">
                <div className="dotdot"></div>
                    <h4>Sed semper orci in fringilla tincidunt</h4>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog2Heading4
