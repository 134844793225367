import React, { useState } from 'react'
import './Bloghero2.css'
import {FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import {blog2Data } from '../../data/Blog2data'
import {ImFacebook} from 'react-icons/im'

const Bloghero2 = () => {

    const [selected, setSelected] = useState(0);

    const b2Length = blog2Data.length;
  return (
    <div className='blogshero'>
        <div className="bbc-social-content">
        <div className="bc-social">
            <a href="">
                        <FaTwitter/>
                    </a>
                    <a href="">
                        <ImFacebook/>
                    </a>
                    <a href="">
                        <FaInstagram/>
                    </a>
                    <a href="">
                        <FaLinkedinIn/>
                    </a>
                    
            </div>
            <div className="bbc-social-divider"></div>
                <div className="bbc-social-text">
                    <h4>Follow me on</h4>
                </div>
        </div>
      <div className="blogshero-content">
        <div className="blogshero-left">
            <div className="syndrome-text">
                <h2>{blog2Data[0].heading}</h2>
            </div>
            <div className="syn-text">
                <p>{blog2Data[selected].review}</p>
            </div>
            <div className="syn-author">
                <h5>{blog2Data[selected].name}</h5>
                <h5>Published: {blog2Data[selected].date}</h5>
            </div>
        </div>
        <div className="blogshero-right">
            <img src={blog2Data[selected].image} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Bloghero2
