import React from 'react'

const Blog3Heading1 = () => {
  return (
    <div className="blogheading1">
        <div className="heading1-content">
          
        <div className="heading1">
            
            <div className="heading1-text">
            <div className="heading1-divider"></div>
                <h2>Heading 1</h2>
            </div>
            <div className="heading1-p">
                <p>Lorem ipsum dolor sit amer, consectetur adispicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                lacus vel facilisis.
                tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adisciping elit, sed do euiusmod tempor incidudunt ut labore
                et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.</p>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Blog3Heading1
