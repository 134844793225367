import React from 'react'
import About from '../components/About/About'
import Career1 from '../components/Career1/Career1'
import Certification from '../components/Certification/Certification'
import Education from '../components/Education/Education'
import Footer from '../components/Footer/Footer'
import Health from '../components/Health/Health'
import Help from '../components/Help/Help'
import Hero from '../components/Hero/Hero'
import Journey from '../components/Journey/Journey'
import Navbar from '../components/Navbar/Navbar'
import Specialist1 from '../components/Specialist1/Specialist1'
import Testimonial from '../components/Testimonials/Testimonial'

const Home = () => {
  return (
 
    <>
    <Navbar/>
    <Hero/>
    <About/>
    <Journey/>
    <Education/>
    <Specialist1/>
    <Career1/>
    <Health/>
    <Certification/>
    <Testimonial/>
    <Help/>
    <Footer/>
    </>
    
  )
}

export default Home