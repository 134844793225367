import React from 'react'
import './Specialist1.css'
import Logo from '../../assets/specialist-logo.png'

const Specialist1 = () => {
  return (
    <div className="specialist" id='Specialization'>
        <div className="specialist-content">
            <div className="specialist-top">
                <div className="specialist-divider"></div>
                <h1>Goal to become</h1>
                <h1>Specialist</h1>
                <p>Dr Fallata he set a new goal to become a movement disorder<br/>
                specialist and then a neuromuscular specialist</p>
            </div>
            <div className="specialist-below">
                <div className="disorder">
                    <img src={Logo} alt="" />
                    <div className="disorder-divider"></div>
                    <h3>Disorder Specialist</h3>
                </div>
                <div className="neuromuscular">
                    <img src={Logo} alt="" />
                    <div className="neuromuscular-divider"></div>
                    <h3>Neuromuscular Specialist</h3>
                </div>
            </div>

            <div className="specialist-end">
                <h3>He achieved both sub-specialities in Manitoba and in Toronto</h3>
            </div>
        </div>
    </div>
  )
}

export default Specialist1
