import React from 'react'
import MainBlog from '../components/MainBlog/MainBlog'
import SecondNavbar from '../components/SecondNavbar/SecondNavbar'

const MainBlogs = () => {
  return (
    <div>
      <SecondNavbar/>
      <MainBlog/>
    </div>
  )
}

export default MainBlogs
