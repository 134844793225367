import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Certificates from "./routes/Certificates";
import Blogs from "./routes/Blogs";
import MainBlogs from "./routes/MainBlogs";
import Blogs1 from "./routes/Blogs1";
import Blogs2 from "./routes/Blogs2";

function App() {
  return (
    
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/certificates" element={<Certificates/>}/>
      <Route path="/blogs" element={<MainBlogs/>}/>  
      <Route path="/blogs1" element={<Blogs/>}/> 
      <Route path="/blogs2" element={<Blogs1/>}/>  
      <Route path="/blogs3" element={<Blogs2/>}/> 
    </Routes>
  );
}

export default App;
