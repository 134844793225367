import React from 'react'
import './BlogsHeading3.css'
import Heading3 from '../../../assets/heading3.png'

const BlogsHeading3 = () => {
  return (
    <div className="heading3">
        <div className="heading3-content">
           <div className="heading3-left">
           <div className="heading3-text">
                <div className="heading3-divider"></div>
                <h2>Heading 3</h2>
            </div>
            <div className="heading3-p">
            <p>Lorem ipsum dolor sit amet, consectetur adispicing elit, sed do eius
                mod tempor<br/> incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gr-<br/>avida. Risus commodo viverra maecenas ac
                cumsan lacus vel facilisis.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adispicing elit, sed do eius
                mod tempor<br/> incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gr-<br/>avida. Risus commodo viverra maecenas ac
                cumsan lacus vel facilisis.</p>
            </div>
           </div>
           <div className="heading3-right">
            <img src={Heading3} alt="" />
           </div>
        </div>
    </div>
  )
}

export default BlogsHeading3
