import React from 'react'
import './Journey.css'
import Video from '../../assets/video.png'
import Pause from '../../assets/pause.png'

const Journey = () => {
  return (
    <div className="journey">
        <div className="j-left">
            <div className="j-divider1"></div>
            <div className="jleft-content">
                <h2>Watch the</h2>
                <h2>Journey</h2>
                <h2>of Dr Ahmed</h2>
                <h2>Fallatah</h2>
            </div>
        </div>
        <div className="j-right">
            <div className="j-video">
              <img src={Video} alt="" />
              <img src={Pause} alt="" />
            </div>
            <div className="j-divider2"></div>
        </div>
    </div>
  )
}

export default Journey
