import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import {FaTwitter, FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa'
import {Link as LinkRoll} from 'react-scroll'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-content">
            <div className="f-divider1"></div>
            <div className="footer-context">
                <div className="context1">
                <div className="context2">
                <LinkRoll activeClass='active'
                      to='About'
                      spy={true}
                      
                      smooth={true}><li>About</li></LinkRoll>
                <LinkRoll activeClass='active'
                      to='Education'
                      spy={true}
                      
                      smooth={true}><li>Education</li></LinkRoll>
                <LinkRoll activeClass='active'
                      to='Specialization'
                      spy={true}
                      
                      smooth={true}><li>Specialization</li></LinkRoll>
                </div>
                <div className="context3">
                <LinkRoll activeClass='active'
                      to='Career'
                      spy={true}
                      
                      smooth={true}><li>Career</li></LinkRoll>
                <LinkRoll activeClass='active'
                      to='Contact'
                      spy={true}
                      
                      smooth={true}><li>Contact</li></LinkRoll>
                </div>
                </div>
                <div className="footer-icons">
                    <div className="f-twitter">
                    <a href="">
                        <FaTwitter/>
                    </a>
                    </div>
                    <div className="f-fb">
                    <a href="">
                        <FaFacebook/>
                    </a>
                    </div>
                    <div className="f-insta">
                    <a href="">
                        <FaInstagram/>
                    </a>
                    </div>
                    <div className="linkedin">
                    <a href="">
                        <FaLinkedin/>
                    </a>
                    </div>
                </div>

                <div className="footer-info">
                    <a href="">
                    <span>info@drahmedfallatah.com.sa</span>
                    </a>
                    <a href="">
                    <span>+966 9200 11633</span>
                    </a>
                    <a href="">
                    <span>Put address here, Jeddah, <br/>
                    Saudi Arabia</span>
                    </a>
                </div>
            </div>
            <div className="help-divider2"></div>
            <div className="f-bottomline">
            <span>Copyright &copy; 2022 Dr Ahmed Fallatah. All rights reserved. All logos, symbols and brands names are copyright to their legal owners</span>
            </div>
        </div>
    </div>
  )
}

export default Footer
