import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './SecondNavbar.css'
import {Link as LinkRoll} from 'react-scroll'
import {FaTimes, FaBars} from 'react-icons/fa'


const SecondNavbar = () => {

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  return (
   
    <nav className="secondnavbar">
      <div className="second-hamburger" onClick={handleClick}>
        {click
         ?
        <FaTimes size={20} style={{color: '#fff'}}/> 
        : 
        <FaBars size={20}/>}
      </div>
        <ul className={click ? "s-nav-links active" : "s-nav-links"}>
        <Link to='/'>
          <li>Home</li>
          </Link>
            <Link to='/certificates'>
              <li>Certificates</li>
              </Link>
            <Link to='/blogs'>
              <li>Blogs</li>
              </Link>
            <LinkRoll 
            activeClass='active'
            to='Contact'
            spy={true}
            smooth={true}>
              <li>Contact</li>
              </LinkRoll>
        </ul>
    </nav>
  
    
  )
}

export default SecondNavbar
