import React from 'react'
import './MainBlog.css'
import MainBlogPic from '../../assets/mainblog.png'
import {FaEye, FaHeart, FaInbox, FaShare} from 'react-icons/fa'
import timg1 from '../../assets/timg1.png'
import timg2 from '../../assets/timg2.png'
import timg3 from '../../assets/timg3.png'
import Help from '../Help/Help'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import {FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import {ImFacebook} from 'react-icons/im'

const MainBlog = () => {
  return (
    <div className='mainblog'>
      <div className="mainblog-content">
      <div className="rc-social-content">
            <div className="rc-social">
            <a href="">
                        <FaTwitter/>
                    </a>
                    <a href="">
                        <ImFacebook/>
                    </a>
                    <a href="">
                        <FaInstagram/>
                    </a>
                    <a href="">
                        <FaLinkedinIn/>
                    </a>
                    
            </div>
            <div className="rc-social-divider"></div>
                <div className="rc-social-text">
                    <h4>Follow me on</h4>
                </div>
                </div>
        <div className="mainblog-first">
        <div className="mainblog-left">
            <div className="mainblog-heading">
                <div className="mainblog-divider"></div>
                <h2>Blogs</h2>
            </div>

            <div className="mainblog-paragraph">
                <span>It is a long established fact that a reader will be distracted by
                    the readable content of a page when looking at its layout. The
                    point of using Lorem Ipsum is that it has a more-or-less normal
                    distribution.
                </span>
            </div>
        </div>
        <div className="mainblog-right">
            <div className="mainblog-bg"></div>
            <div className="mainblog-pic">
                <img src={MainBlogPic} alt="" />
            </div>
        </div>
      </div>

      {/* below part */}

      <div className="mainblog-firstrow">
      <div className="mt-second">
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg1} alt="" />
                    </div>
                   <div className="tt-content">
                   <div className="card-content">
                        <h4>Structural Barriers to<br/>
                        Healthy Lifestyles</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                        <a href="/blogs1">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                   </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg2} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Elsberg<br/>
                        Syndrome</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs2">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg3} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Dementia vs.<br/>
                        Alzheimer's</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs3">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                                <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>

      {/* second row */}

      <div className="mainblog-secondrow">
      <div className="mt-second">
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg3} alt="" />
                    </div>
                   <div className="tt-content">
                   <div className="card-content">
                        <h4>Dementia vs.</h4>
                        <h4> Alzheimers</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                        <a href="/blogs3">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                   </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg1} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                    <h4>Structural Barriers to<br/>
                        Healthy Lifestyles</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs1">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg2} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                    <h4>Elsberg<br/>
                        Syndrome</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs2">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                                <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>

      {/* thrid row */}

      <div className="mainblog-thirdrow">
      <div className="mt-second">
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg1} alt="" />
                    </div>
                   <div className="tt-content">
                   <div className="card-content">
                        <h4>Structural Barriers to<br/>
                        Healthy Lifestyles</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                        <a href="/blogs1">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                   </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg2} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Elsberg<br/>
                        Syndrome</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <a href="/blogs2">
                            <button>Learn More</button>
                            </a>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg3} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Dementia vs.<br/>
                        Alzheimer's</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <Link to="/blogs3">
                            <button>Learn More</button>
                            </Link>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                                <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>
      </div>
      <div className="mainblog-foot">
      <Help/>
      <Footer/>
      </div>
    </div>
  )
}

export default MainBlog
