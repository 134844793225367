import React from 'react'
import Footer from '../components/Footer/Footer'
import Help from '../components/Help/Help'
import Navbar from '../components/Navbar/Navbar'
import RoutesCertificate from '../components/RoutesCertificate/RoutesCertificate'
import SecondNavbar from '../components/SecondNavbar/SecondNavbar'

const Certificates = () => {
  return (
    <div>
      <SecondNavbar/>
      <RoutesCertificate/>
      <Help/>
      <Footer/>
    </div>
  )
}

export default Certificates