import React from 'react'
import './MoreBlogs.css'
import {FaEye, FaHeart, FaInbox, FaShare} from 'react-icons/fa'
import timg4 from '../../../assets/timg1.png'
import timg5 from '../../../assets/timg2.png'
import timg6 from '../../../assets/timg3.png'
import { Link } from 'react-router-dom'

const MoreBlogs = () => {
  return (
    <div className="moreblogs">
        <div className="moreblogs-content">
            <div className="moreblogs-text">
                <div className="moreblogs-div"></div>
                <h2>More Blogs</h2>
            </div>
     
        <div className="t-card">
                    <div className="t-img">
                        <img src={timg4} alt="" />
                    </div>
                   <div className="tt-content">
                   <div className="card-content">
                        <h4>Structural Barriers to<br/>
                        Healthy Lifestyles</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <button>Learn More</button>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                   </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg5} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Elsberg<br/>
                        Syndrome</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <button>Learn More</button>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                            <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="t-card">
                    <div className="t-img">
                        <img src={timg6} alt="" />
                    </div>
                    <div className="tt-content">
                    <div className="card-content">
                        <h4>Dementia vs.<br/>
                        Alzheimer's</h4>
                        <h5>In 2017 Dr Fallata came back to Saudi Arabia and<br/>
                        started to work as an assistant professor at University<br/>
                        of Tabuk. In 2018 he became a supervisor of...</h5>
                    </div>
                    <div className="t-learn">
                        <div className="t-learn-btn">
                            <button>Learn More</button>
                        </div>
                        <div className="t-social">
                            <div className="eye">
                                <a href="">
                                    <FaEye/>
                                </a>
                                <span>500</span>
                            </div>
                            <div className="heart">
                            <a href="">
                                    <FaHeart/>
                                </a>
                                <span>256</span>
                            </div>
                            <div className="inbox">
                            <a href="">
                                    <FaInbox/>
                                </a>
                                <span>35</span>
                            </div>
                            <div className="share">
                            <a href="">
                                    <FaShare/>
                                </a>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
            <div className="blogs-btn">
                <Link to='/blogs'>
                <button>View all</button>
                </Link>
            </div>
        </div>

  )
}

export default MoreBlogs
