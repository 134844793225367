import React from 'react'
import Blog2heading1 from '../components/Blog2/Blog2heading1/Blog2heading1'
import Blog2Heading2 from '../components/Blog2/Blog2Heading2/Blog2Heading2'
import Blog2Heading3 from '../components/Blog2/Blog2Heading3/Blog2Heading3'
import Blog2Heading4 from '../components/Blog2/Blog2Heading4/Blog2Heading4'
import MoreBlogs from '../components/BlogsComponent/MoreBlogs/MoreBlogs'

import BlogsHero2 from '../components/Blog2/Bloghero2'
import Footer from '../components/Footer/Footer'
import Help from '../components/Help/Help'
import SecondNavbar from '../components/SecondNavbar/SecondNavbar'

const Blogs1 = () => {
  return (
    <div>
      <SecondNavbar/>
      <BlogsHero2/>
      <Blog2heading1/>
      <Blog2Heading2/>
      <Blog2Heading3/>
      <Blog2Heading4/>
      <MoreBlogs/>
      <Help/>
      <Footer/>
    </div>
  )
}

export default Blogs1
