import React from 'react'
import './Hero.css'
import { FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import fblogo from '../../assets/fb.png'
import Linkedin from '../../assets/linkedin.png'
import {ImFacebook, } from 'react-icons/im'

const Hero = () => {
  return (
    <div className="hero">
        <div className="hero-content">
            <div className="hero-left">
                <div className="hero-social">
                <div className="hero-icons">
                <a href="">
                        <FaTwitter/>
                    </a>
                    <a href="" className='fb'>
                        <ImFacebook/>
                    </a>
                    <a href="">
                        <FaInstagram/>
                    </a>
                    <a href="" className='linkedin'>
                        <FaLinkedinIn/>
                    </a>
                </div>
                <div className="social-divider"></div>
                <div className="social-text">
                    <h4>Follow me on</h4>
                </div>
                </div>
            </div>
            <div className="hero-right">
            <h1>Dr. Ahmad Fallata</h1>
            <div className="hero-divider"></div>
            <p>a 2005 graduate from Umm Al Qura university wanted  to<br/>
            accomplish his Dream of becoming one of Saudi Arabia's<br/>
            best neurologist</p>
            <div className="hero-btn">
            <button>Contact Now</button>
        </div>
            </div>
        </div>
       
    </div>
  )
}

export default Hero
