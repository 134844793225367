import React from 'react'
import './Education.css'
import Plus from '../../assets/plus.png'
import Plus1 from '../../assets/plus1.png'

const Education = () => {
  return (
    <div className="education" id='Education'>
        <div className="edu-content">
            <div className="plus">
                <img src={Plus} alt="" />
                <img src={Plus1} alt="" />
            </div>
            <div className="edu-divider1"></div>
            <div className="edu-text">
                <h2>Education</h2>
            </div>
        </div>
        <div className="edu-below">
            <div className="edu-grid">
                <div className="two-five">
                <h2>2005</h2>
                <div className="five-box"></div>
                </div>
                <div className="edu-uni-content">
                <h3>Umm Al Qura university</h3>
                <h5>Graduanted from Umm Al Qura university.</h5>
                </div>
            </div>
            <div className="edu-grid1">
                <div className="two-six">
                <h2>2006-2009</h2>
                <div className="two-six-box"></div>
                </div>
                <div className="edu-uni-content">
                <h3>King Abdulaziz Hospital</h3>
                <h5>Worked as a cheif resident in King Abdulaziz hospital</h5>
            </div>
            </div>
            <div className="edu-grid2">
        <div className="two-nine">
                <h2>2009</h2>
                <div className="2009-box"></div>
                </div>
                <div className="edu-uni-content">
                <h3>University of Tabuk</h3>
                <h5>Joined University of Tabuk as a demonstrator</h5>
            </div>
            </div>
            <div className="edu-grid3">
                <div className="two-ten">
                <h2>2010</h2>
                <div className="2010-box"></div>
                </div>
                <div className="edu-uni-content">
                <h3>University of Manitoba</h3>
                <h5>Started neurology residency at university of Manitoba in Canada
                Within Five years, he successfully passed the Royal College exams and 
                became a neurologist</h5>
            </div>
            </div>
        </div>
        
     
    </div>
  )
}

export default Education
