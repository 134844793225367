import React from 'react'
import Blog3Heading1 from '../components/Blog3/Blog3Heading1/Blog3Heading1'
import Blog3Heading2 from '../components/Blog3/Blog3Heading2/Blog3Heading2'
import Blog3Heading3 from '../components/Blog3/Blog3Heading3/Blog3Heading3'
import Blog3Heading4 from '../components/Blog3/Blog3Heading4/Blog3Heading4'
import Blog3Hero from '../components/Blog3/Blog3Hero/Blog3Hero'
import Footer from '../components/Footer/Footer'
import Help from '../components/Help/Help'
import SecondNavbar from '../components/SecondNavbar/SecondNavbar'
import MoreBlogs from '../components/BlogsComponent/MoreBlogs/MoreBlogs'

const Blogs2 = () => {
  return (
    <div>
      <SecondNavbar/>
      <Blog3Hero/>
      <Blog3Heading1/>
      <Blog3Heading2/>
      <Blog3Heading3/>
      <Blog3Heading4/>
      <MoreBlogs/>
      <Help/>
      <Footer/>
    </div>
  )
}

export default Blogs2
