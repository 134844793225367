import React from 'react'
import './Health.css'
import HealthAuthor from '../../assets/health-author.png'

const Health = () => {
  return (
    <div className="health">
       <div className="health-content">
       <div className="health-left">
            <img src={HealthAuthor} alt="" />
        </div>
        <div className="health-right">
            <h5>He always says to his patients</h5>
            <div className="hr-content">
            <h2><span>''</span>health is a choice<br/>
            and you choose to<br/>
            go to the gym and<br/>
            you can choose to<br/>
            eat healthy<span>''</span></h2>
            </div>
            <div className="health-below">
                <h5>Dr Fallata's passion is to improve health and help the<br/>
                community to adapt a healthy lifestyle</h5>
                <h5>Dr Fallata is also an entrepreneur who Co-fauled SIRCH & SBC</h5>
            </div>
        </div>
       </div>
    </div>
  )
}

export default Health
