import React from 'react'
import Heading2 from '../../../assets/heading2.png'

const Blog3Heading2 = () => {
  return (
    <div className="heading2">
    <div className="heading2-content">
        <div className="heading2-left">
            <img src={Heading2} alt="" />
        </div>
        <div className="heading2-right">
            <div className="heading2-heading">
                <div className="heading2-divider"></div>
                <h2>Heading 2</h2>
            </div>
            <div className="heading2-p">
            <p>Lorem ipsum dolor sit amet, consectetur adispicing elit, sed do eius-<br/>
            mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum<br/>
            suspendisse ultrices gravida. Risus commodo viverra maecenas ac-<br/>
            cumsan lacus vel facilisis.</p>
            <p>empor incididunt ut labore et dolore magna aliqua. Quis ipsum sus-<br/>
            pendisse ultrices gravida.</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default Blog3Heading2
