import React from 'react'
import './Career1.css'
import img1 from '../../assets/career1.png'
import img2 from '../../assets/career2.png'
import img3 from '../../assets/career3.png'

const Career1 = () => {
  return (
    <div className="career" id='Career'>
        <div className="career-content">
             {/* left content  */}
            <div className="career-left">
                <div className="career-first">
                <div className="career-career">
                <div className="career-divider"></div>
                <h2>Career</h2>
                </div>
                <h4>Assistant Professor at University of Tabuk</h4>
                <h5>In 2017 Dr Fallata came back to Saudi Arabia and started to work as an assistant
                professor at University of Tabuk. In 2018 he became a supervisor of internship
                program</h5>
                </div>
                <div className="career-second">
                    <h5>in less than a year He became a...</h5>
                    <h2>permanent figure<br/>
                    in the <span>neurology<br/>community</span> in<br/>
                    Saudi Arabia</h2>
                </div>
            </div>

            {/* right content */}
            <div className="career-right">
                <div className="career-right-img">
                    <div className="img1">
                    <img src={img1} alt="" />
                    </div>
                    <div className="img2">
                    <img src={img2} alt="" />
                    </div>
                    <div className="img3">
                    <img src={img3} alt="" />
                    </div>
                </div>
                <div className="career-right-content">
                    <h5>Also he worked at medical Fakeeh (Saudia airline clinic) in May 20 21 and he was chosen by his patients as one<br/>
                    of the best doctors at SAUDIA. Academically Dr Fallata participated in local and regional conferences.</h5>
                    <h5>Beside medicine Dr Fallata finishes MBA from MBSC in 2022 and currently he is the acting medical director at<br/>
                    European Medical Center in Jeddah....</h5>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Career1
