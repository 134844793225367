import React, { useState } from 'react'
import './BlogsHero.css'
import {FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import { testimonialData } from '../../../data/testimonialData'
import { Link } from 'react-router-dom'
import {ImFacebook} from 'react-icons/im'

const BlogsHero = () => {

    const [selected, setSelected] = useState(0);

    const tLength = testimonialData.length;

  return (
    <div className='blogshero'>
        <div className="bbc-social-content">
        <div className="bc-social">
            <Link to="">
                        <FaTwitter/>
                    </Link>
                    <Link to="">
                        <ImFacebook/>
                    </Link>
                    <Link to="">
                        <FaInstagram/>
                    </Link>
                    <Link to="">
                        <FaLinkedinIn/>
                    </Link>
                    
            </div>
            <div className="bbc-social-divider"></div>
                <div className="bbc-social-text">
                    <h4>Follow me on</h4>
                </div>
        </div>
      <div className="blogshero-content">
        <div className="blogshero-left">
            <div className="syndrome-text">
                <h2>{testimonialData[selected].heading}</h2>
                {/* <h2>Elsberg</h2>
                <h2>Syndrome</h2> */}
            </div>
            <div className="syn-text">
                <p>{testimonialData[selected].review}</p>
            </div>
            <div className="syn-author">
                <h5>Dr. Ahmed Fallata</h5>
                <h5>Published: June 8, 2022</h5>
            </div>
        </div>
        <div className="blogshero-right">
            <img src={testimonialData[selected].image} alt="" />
            {/* <img src={SyndromeAuthor} alt="" /> */}
        </div>
      </div>
    </div>
  )
}

export default BlogsHero
